/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';
import banner from '../../images/events/2024/avs/asia-video-summit-desktop-hero.jpg';
import mobileBanner from '../../images/events/2024/avs/asia-video-summit-mobile-hero.jpg';
import ogimage from '../../images/events/2024/avs/asia-video-summit-og-image.jpg';

export default function AsiaVideoSummit2024() {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='Asia Video Summit 2024 - Witbe' isEvents>
            <SEO
                title='Meet Witbe‘s team at Asia Video Summit'
                ogDescription='Meet the Witbe team in Hong Kong at Asia Video Summit 2024 on March 13 – 14! Schedule a live demo of our AI-powered automated video testing technology.'
                description='Discover how you can improve your QoE with our video testing and monitoring technology, now faster with more AI.'
                article='article'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={9} banner={mobileBanner} /> : <DesktopEventPage id={9} banner={banner} />}
        </Layout>
    )
}
